@import '../../scss/variables';

$footerColor: lighten($black, 80%);

.footerContainer {
  background: lighten($black, 20%);
  color: $footerColor;

  .row {
    padding: $size * 2 0;

    &.max {
      padding: 0;
    }

    .socialIcon {
      svg {
        font-size: 5vw;

        &:hover {
          color: $white;
        }
      }
    }
  }

  .hr {
    border-top: 1px solid $footerColor;
  }

  .company {
    div {
      a {
        cursor: pointer;
        
        &:link {
          color: $footerColor;
          font-weight: bold;
          text-decoration: none;
        }
        &:visited {
          @extend :link;
        }
        &:hover {
          color: $footerColor;
          text-decoration: underline;
        }
        &:active {
          @extend :hover;
        }
      }

      @media screen and (max-width: $size * 55) {
        line-height: 1.8;
      }
    }
  }
}