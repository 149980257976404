@import '../../../scss/variables';

.spaceContainer {
  padding-bottom: $size;
  background-color: #ffeaa8;
  border-bottom-style: solid;
  border-bottom-width: $size;  
  border-bottom-color: #f00;
  
  &:nth-child(odd) {
    background-color: #a8f2ff; 
    border-bottom-color: #00f;
  }

  &.RIGHT {
    &.moh {
      background-position-x: 100%;

      @media screen and (min-width: $size * 100) {
        background-position-x: 90%;
      }

      @media screen and (max-width: $size * 100) {
        background-position-x: 110%;
      }
    }
  }

  &.LEFT {
    &.moh {
      background-position-x: 0;

      @media screen and (min-width: $size * 100) {
        background-position-x: 10%;
      }
      
      @media screen and (max-width: $size * 100) {
        background-position-x: -10%;
      }
    }
  }
  
  &.moh {
    background-image: url('../../../img/moh.png');
    background-position-y: $size * 2;
    background-repeat: no-repeat;
    background-size: 30vw auto;

    @media screen and (max-width: $size * 100) {
      background-size: 40vw auto;
    }

    @media screen and (max-width: $size * 55) {
      background-image: none;

      .mobileIcon {
        background-image: url('../../../img/moh.png');
        background-size: 80%;
        background-repeat: no-repeat;
        background-position: top right;
      }
    }
  }

  &.sploodie {
    @extend .moh;

    background-image: url('../../../img/sploodie.png');
    background-size: 20%;

    @media screen and (max-width: $size * 100) {
      background-size: 38vw auto;
    }

    @media screen and (max-width: $size * 55) {
      background-image: none;

      .mobileIcon {
        background-image: url('../../../img/sploodie.png');
        background-size: 80%;
        background-repeat: no-repeat;
        // TODO: make real thumbnails and flipped images
        transform: scaleX(-1);
        background-position: top left;
      }
    }
  }

  h2 {
    padding: $size 0;
    font-weight: bold;
  }

  p {
    font-size: 1.75em;
  }

  .mobileIcon {
    display: none;
  }

  @media screen and (max-width: $size * 55) {
    .mobileIcon {
      float: right;
      display: block;
      height: $size * 13;
      width: $size * 10;
      margin-left: $size;
    }

    p {
      font-size: 1em;
    }
  }
}