@import '../../../scss/variables';

.spacer {
  display: block;
  width: 100%;
  height: $size * 17;
  background: transparent none;
}

@media screen and (max-width: $size * 80) {
  .spacer {
    height: $size * 10;
  }
}

@media screen and (max-width: $size * 66) {
  .spacer {
    height: $size * 8;
  }
}

@media screen and (max-width: $size * 55) {
  .spacer {
    height: $size * 5;
  }
}