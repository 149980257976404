$mawWidth: $size * 100;

.container {
  min-width: 300px;
  width: 100%;

  & * {
    box-sizing: border-box; 
  }

  .row {
    margin: 0 auto;
    width: 100%;
    max-width: $mawWidth;

    &::before {
      content: '';
      display: table;
      clear: both;
    }

    &::after {
      @extend ::before;
    }

    [class*='col-'],
    [class*='oneOf-'] {
      float: left;
      min-height: 1px;
      width: $col-width;
      padding: 0 $size / 2;
    }

    &.max {
      max-width: 100%;
    }

    .alpha {
      padding-left: 0;
    }
    
    .omega {
      padding-right: 0;
    }

    @for $i from 1 through $cols {
      .col-#{$i} {
        width: $col-width * $i;
      }

      .oneOf-#{$i} {
        width: 100% / $i;
      }
    }
  }
}