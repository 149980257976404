@import './scss/variables';

.clear:after {
  clear: both;
  content: '';
  display: block;
  font-size: 0;
  line-height: 0;
  visibility: hidden;
  width: 0;
  height: 0
}

.left {
  float: left;
}

.right {
  float: right;
}

.center {
  margin: 0 auto;
}

.alignCenter {
  text-align: center;
}

.mainContainer {
  position: relative;
  width: 860px;
  margin: 20px auto;
}

.imgContainer {
  max-width: $size * 17;

  img {
    width: 100%;
  }
}