@import '../../../scss/variables';

.titleContainer {
  position: absolute;
  top: 20%;
  z-index: $zIndex + 10;
  border-top: 1px solid $black;
  border-right: 1px solid transparent;
  border-bottom: 1px solid $black;
  border-left: 1px solid transparent;

  &.noVid {
    position: relative;
    top: 0;
  }
  
  h1 {
    padding-top: $size;
    color: #000;
    font-size: $fontSize * 8;
    font-family: $fontFamily;    
    background: $white;
    background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.7) 45%, rgba(255,255,255,.7) 55%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,.7) 45%,rgba(255,255,255,.7) 55%,rgba(255,255,255,0) 100%);
    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,.7) 45%,rgba(255,255,255,.7) 55%,rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 );
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+40,1+60,0+100 */

    @media screen and (max-width: $size * 66) {
      font-size: 60px;
    }

    @media screen and (max-width: $size * 55) {
      padding-top: 0;
    }

    @media screen and (max-width: $size * 38) {
      padding-top: $size / 2;
      font-size: 48px;
    }
  
    @media screen and (max-width: $size * 28) {
      font-size: 32px;
    }
  }

  h2 {
    @extend h1;
    font-size: $fontSize * 5.25;
    padding-top: 0;
    padding-bottom: $size;

    @media screen and (max-width: $size * 66) {
      font-size: 37px;
    }

    @media screen and (max-width: $size * 55) {
      padding-bottom: $size / 2;
    }

    @media screen and (max-width: $size * 38) {
      font-size: 30px;
    }
  
    @media screen and (max-width: $size * 28) {
      display: none;
    }
  }

  @media screen and (max-width: $size * 66) {
    top: 20%;
  }

  @media screen and (max-width: $size * 55) {
    position: relative;
  }

  @media screen and (max-width: $size * 38) {
    border-top: none;
    border-right: none;
    border-left: none;
  }
}