@import '../../../scss/variables';

.menuContainer {
  position: absolute;
  bottom: 2%;
  z-index: $zIndex + 20;
  width: 100%;

  &.noVid {
    position: relative;
  }

  a {
    display: block;
    width: 100%;
    background: rgba($white, .7);
    font-size: 2em;
    line-height: 2;
  }
}

@media screen and (max-width: $size * 55) {
  .menuContainer {
    position: relative;

    li {
      border-right: 1px solid #000;
      border-bottom: 1px solid #000;
      border-left: 1px solid #000;

      &:first-child {
        border-right: none;
      }
      
      &:last-child {
        border-left: none;
      }

      a {
        font-size: 1.5em;
        background: $white;
      }
    }
  }
}

@media screen and (max-width: $size * 38) {
  .menuContainer {
    li {
      display: block;
      width: 100%;
      border-top: none;
      border-right: none;
      border-bottom: 1px solid #000;
      border-left: none;

      a {
        text-align: center;
      }
    }
  }
}