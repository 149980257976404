@import '../../../scss/variables';

.largeVideoContainer {
  position:  relative;
  max-width: 100%;
  z-index: $zIndex;

  .pauseButton {
    position: absolute;
    bottom: 10%;
    right: 2%;
    z-index: $zIndex * 2;
    background: #fff;
    display: block;
    height: $size * 3;
    width: $size * 3;
    color: #000;
    opacity: .5;

    &:hover {
      opacity: 1;
    }
  }
}

@media screen and (max-width: $size * 122) {
  .largeVideoContainer {
    .pauseButton {
      bottom: 15%;
      right: 5%;
    }
  }
}

@media screen and (max-width: $size * 100) {
  .largeVideoContainer {
    .pauseButton {
      bottom: 20%;
      right: 5%;
    }
  }
}