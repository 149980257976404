@import './scss/variables';
@import './scss/grid';

@viewport {
  zoom: 1.0;
  width: extend-to-zoom;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
html {
  height: 100%;
}
body {
  line-height: 1;
  min-height: 100%;
  font-family: sans-serif;
  background: #fff url('./img/background.jpg') no-repeat top center fixed;
  background-size: cover;
}
nav ol,
nav ul,
.menu ol,
.menu ul,
ol.menu,
ul.menu {
  margin: 0;
  padding: 0;
  list-style: none;
}
nav li,
.menu li {
  display: inline-block;
}
blockquote, q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
b,
strong {
  font-weight: bold;
}
img {
  display: block;
}
img,
embed,
object,
video {
  max-width: 100%;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button,
input,
textarea {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
a,
button,
imput,
textarea,
img,
video {
  outline: none;

  &:focus {
    outline: none;
  }
}
textarea {
  resize: none;
  padding: $size / 2;
}

h1 {
  font-size: 48px;
}
h2 {
  font-size: 36px;
}

p {
  line-height: 1.5;
  margin: 0 0 ($size * 2) 0;

  &.plain {
    &:before {
      padding: 0;
    }
  }

  &:before {
    content: "";
    padding: 0 0 0 $size * 2;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

a {
  &:link {
    color: $black;
    font-weight: bold;
    text-decoration: none;
  }
  &:visited {
    @extend :link;
  }
  &:hover {
    color: $black;
    text-decoration: underline;
  }
  &:active {
    @extend :hover;
  }
}

button {
  background: #7db9e8;
  background: -moz-linear-gradient(top,  #7db9e8 0%, #207cca 49%, #2989d8 50%, #1e5799 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#7db9e8), color-stop(49%,#207cca), color-stop(50%,#2989d8), color-stop(100%,#1e5799));
  background: -webkit-linear-gradient(top,  #7db9e8 0%,#207cca 49%,#2989d8 50%,#1e5799 100%);
  background: -o-linear-gradient(top,  #7db9e8 0%,#207cca 49%,#2989d8 50%,#1e5799 100%);
  background: -ms-linear-gradient(top,  #7db9e8 0%,#207cca 49%,#2989d8 50%,#1e5799 100%);
  background: linear-gradient(to bottom,  #7db9e8 0%,#207cca 49%,#2989d8 50%,#1e5799 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7db9e8', endColorstr='#1e5799',GradientType=0 );
  color: $white;
  font-weight: bold;
  border: 1px solid $black;
  border-radius: $size / 2;
  padding: ($size / 2) $size;
  cursor: pointer;

  &:hover {
    background: #1e5799;
    background: -moz-linear-gradient(bottom,  #7db9e8 0%, #207cca 49%, #2989d8 50%, #1e5799 100%);
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0%,#7db9e8), color-stop(49%,#207cca), color-stop(50%,#2989d8), color-stop(100%,#1e5799));
    background: -webkit-linear-gradient(bottom,  #7db9e8 0%,#207cca 49%,#2989d8 50%,#1e5799 100%);
    background: -o-linear-gradient(bottom,  #7db9e8 0%,#207cca 49%,#2989d8 50%,#1e5799 100%);
    background: -ms-linear-gradient(bottom,  #7db9e8 0%,#207cca 49%,#2989d8 50%,#1e5799 100%);
    background: linear-gradient(to top,  #7db9e8 0%,#207cca 49%,#2989d8 50%,#1e5799 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#7db9e8',GradientType=0 );
  }

  &:disabled {
    color: $black;
    cursor: auto;
    background: #888;
  }
}
