@import '../../../scss/variables';

.mediaContainer {
  background-color: transparent;

  .mediaPlayerContainer {
    background: $black;
    width: 100%;
  }

  .thumbNailContainer {
    background: $black;
    width: 100%;
    padding: $size $size / 2 $size;

    > div:first-child {
      padding-bottom: $size;
    }

    .row {
      &:last-child {
        margin-bottom: 0;
      }
    }

    .thumb {
      position: relative;

      &.active {
        .carrot {
          display: block;
        }
        .icon {
          color: white;
        }
      }

      .carrot {
        display: none;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -$size * 2;
        height: 0;
        width: 0;
        border-top: $size * 2 solid $black;
        border-right: $size * 2 solid transparent;
        border-bottom: none;
        border-left: $size * 2 solid transparent;
      }

      .iconAngle {
        position: absolute;
        bottom: 0;
        left: $size / 2;
        height: 0;
        width: 0;
        border-top: $size * 2 solid transparent;
        border-right: $size * 2 solid transparent;
        border-bottom: $size * 2 solid $primaryColor;
        border-left: $size * 2 solid $primaryColor;
      }

      .icon {
        font-size: $size * 2.5;
        color: $black;
        position: absolute;
        bottom: $size / 2;
        left: $size * 2;
        margin: (-$size / 2) 0 0 (-$size / 2);
      }

      img {
        cursor: pointer;
      }
    }

    button {
      font-size: 2em;
      width: 100%;
    }
  }
}